import _ from 'lodash';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { NameInput, EmailInput } from './SimpleInputs';

export default class AuthForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: {
        email: '',
        name: ''
      },
      valid: undefined,
    };
    this.inputStates = {};
  }

  getInputs(props) {
    const inputs = {};

    Object.keys(this.state.inputs).forEach(key => {
      inputs[key] = props[key];

      if (typeof props[key] === 'object') {
        inputs[key] = _.cloneDeep(props[key]);
      }
    });

    return inputs;
  }

  setInitialState() {
    this.initialState = this.getInputs(this.state.inputs);

    setTimeout(() => {
      this.forceUpdate();
    }, 1000);
  }

  hasChanged() {
    // return !_.isEqual(this.state.inputs, this.initialState);
    return JSON.stringify(this.state.inputs) !== JSON.stringify(this.initialState);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading === false && this.props.loading === true) {
      this.setInitialState();
      this.resetInputs();

      if (this.onDataUpdate) {
        this.onDataUpdate();
      }
    }
  }

  resetInputs() {
    for (const key in this.inputs) {
      let input = this.inputs[key];

      if (typeof input.getWrappedInstance === 'function') {
        input = input.getWrappedInstance();
      }

      console.log(input);
      if (input && input.reset) {
        input.reset();
      }
    }
  }

  removeRef(key) {
    if (!this.inputs) {
      return;
    }
    if (key) {
      delete this.inputs[key];
    }
  }

  setRef(element) {
    if (!this.inputs) {
      this.inputs = {};
    }
    if (element) {
      this.inputs[element.props.name] = element;
    }
  }

  submitAction() {
    this.setState({ loading: true });
    setTimeout(() => this.setState({ loading: undefined }));
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    let valid = true;

    for (const key in this.inputs) {
      let input = this.inputs[key];

      if (typeof input.getWrappedInstance === 'function') {
        input = input.getWrappedInstance();
      }

      if (input && input.validate && !input.validate()) {
        valid = false;
      }
    }

    if (valid) {
      this.submitAction(this.state.inputs);
    } else {
      this.setState({ valid: false });
      setTimeout(() => this.setState({ valid: undefined }), 2000);
    }
  }

  onChange(e) {
    const state = this.state.inputs;
    state[e.target.name] =  e.target.value;
    this.setState({ inputs: state }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.inputs);
      }
    });
  }

  onValidate(name, isValid) {
    this.inputStates[name] = isValid;
  }

  renderInputs() {
    return (
      <div className="inputs">

        <EmailInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Email Address"
          type="email"
          placeholder="name@company.com"
          name="email"
          value={this.state.inputs.email}
          errorMessage="Please enter a valid email address."
          ref={this.setRef.bind(this)}
        />

        <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Full Name"
          type="text"
          placeholder="Full Name"
          name="name"
          value={this.state.inputs.name}
          errorMessage="Please enter your full name."
          ref={this.setRef.bind(this)}
        />

      </div>
    );
  }

  renderActions() {
    return (
      <div className="actions">
        <button
          type="submit"
          name="submit"
        >
          <span>Auth Form!</span>
          <span></span>
        </button>

        <div className="login">
          <div>Generic Thing?</div>
          <Link to="/login">Do It</Link>
        </div>
      </div>
    )
  }

  render() {
    return (
      <form onSubmit={this.onSubmit.bind(this)} className={`${this.state.valid === false ? 'invalid' : ''} ${this.state.loading || this.props.loading ? 'loading' : ''} ${this.props.className}`}>
        <div className="card">
          { this.renderInputs() }
          { this.renderActions() }
        </div>
      </form>
    );
  }
}
