import React, { Component } from 'react';

import Toggle from './Toggle';
import Tooltip from './Tooltip';
import InlineInput from './InlineInput';
import { AuthSubmitButton } from './Buttons';
import Switch from './Switch';
import HeaderButtons from './HeaderButtons';

export default class DynamicRenderInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.initialState = { ...this.state };
  }

  onChange(name, value, ignoreFormatting) {
    const state = { ...this.state };

    if (ignoreFormatting) {
      state[name] = value;
    } else {
      state[name] = value.replace(/[^\d.-]/g,'');
    }

    this.props.onChange(state);
  }

  renderInputs() {
    return (
      <div style={{ marginTop: -13 }}>
        <InlineInput
          label="Duration"
          tooltip={<Tooltip>Example: Enter 0 if you want it to activate automatically or 3 if you want it to activate after 3 seconds.</Tooltip>}
          name="renderDuration"
          value={ this.props.renderDuration }
          prefix="Render after"
          suffix={<span>seconds spent <div className="select-wrapper"><select value={this.props.renderDurationType} onChange={(e) => {
            const { name, value } = e.target;
            this.onChange(name, value, true);
          }} name="renderDurationType"><option value="page">on a single page</option><option value="site">on the whole site</option></select></div></span>}
          idx={0}
          onChange={this.onChange.bind(this)}
        />

        <InlineInput
          label="Position"
          tooltip={<Tooltip>Example: Enter 0 if you want it to activate automatically when the page is loaded or 100 if you want it to activate when the user is scrolled to the very bottom.</Tooltip>}
          name="renderPosition"
          value={ this.props.renderPosition }
          prefix="Render when the user is"
          suffix="% from the top of the page."
          idx={1}
          onChange={this.onChange.bind(this)}
        />

        <div className="inline-input">
          <label>Device<Tooltip>Choose if you want to render the survey on all devices or just one or the other.</Tooltip></label>

          <Switch
            options={[
              { label: 'All Devices', value: false },
              { label: 'Desktop Only', value: 'desktop' },
              { label: 'Mobile Only', value: 'mobile' },
            ]}
            value={this.props.renderDevice}
            onChange={(value) => {
              this.props.onChange({ renderDevice: value });
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    let inputs = null;
    let advancedInputs = null;

    if (this.props.showDynamicRules === 'custom') {
      inputs = (this.renderInputs());
    }

    let options = [
      { label: "Show immediately", value: false },
      { label: "Show based on rules", value: 'custom' },
    ];

    return (<div
      className={`dynamic-render-input ${this.props.disabled ? 'disabled' : ''}`}
      active={this.props.active}
    >
      <div style={{ position: 'relative', marginBottom: this.props.showDynamicRules ? 25 : 0, marginTop: -15 }}>
        { this.props.warning }
        { this.props.toggleWarning }
        <label>Launcher Pop-up Rules<Tooltip>This is useful if you only want the survey to appear depending on: device, page duration, or scroll position.</Tooltip></label>
        <div className="inline-toggle">
          <Switch
            value={this.props.showDynamicRules || false}
            options={options}
            onChange={(value) => {
              this.props.onToggle(value);
              if (!value) {
                this.props.onChange({ renderPosition: false, renderDuration: false, renderDevice: false });
              } else {
                this.props.onChange({
                  renderPosition: '0',
                  renderDuration: '0',
                });
              }
            }}
          />
          {/*<div className="subtle">Make the launcher appear based on rules like device, scroll position, and time on your website.</div>*/}
        </div>
      </div>

      { inputs }
    </div>);
  }
}