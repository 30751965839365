import React, { Component } from 'react';
import { renderNumber } from '../utils';

import { encode, loadContactUsSurvey } from '../utils';

import Tooltip from '../components/Tooltip';
import SectionHeader from '../components/SectionHeader';

function getEmailLimit(account) {
  const { plan } = account;
  let emailLimit = plan.emailLimit;
  if (!emailLimit) {
    emailLimit = 100;

    if (plan.key === 'lite') {
      emailLimit = 100;
    }
    if (plan.key === 'basic') {
      emailLimit = 1000;
    }
    if (plan.key === 'standard') {
      emailLimit = 2500;
    }
    if (plan.key === 'plus') {
      emailLimit = 5000;
    }
    if (plan.key === 'premium') {
      emailLimit = 10000;
    }
    if (plan.key === 'pro') {
      emailLimit = 10000;
    }
    if (plan.key === 'enterprise') {
      emailLimit = 20000;
    }
  }
  return emailLimit;
}

function getSmsLimit(account) {
  const { plan } = account;
  let smsLimit = plan.smsLimit;
  if (!smsLimit) {
    smsLimit = 10;

    if (plan.key === 'lite') {
      smsLimit = 10;
    }
    if (plan.key === 'basic') {
      smsLimit = 10;
    }
    if (plan.key === 'standard') {
      smsLimit = 500;
    }
    if (plan.key === 'plus') {
      smsLimit = 1000;
    }
    if (plan.key === 'premium') {
      smsLimit = 2000;
    }
    if (plan.key === 'pro') {
      smsLimit = 2000;
    }
    if (plan.key === 'enterprise') {
      smsLimit = 3000;
    }
  }
  return smsLimit;
}

export default class MonthlySubmissionCounter extends Component {
  render() {
    const submissionLimit = this.props.account.plan.submissionLimit;
    const submissionCount = this.props.account.submissionCount;

    const emailLimit = getEmailLimit(this.props.account);
    const emailCount = this.props.account.emailCampaignCount || 0;

    const smsLimit = getSmsLimit(this.props.account);
    const smsCount = this.props.account.smsCount || 0;

    // if (submissionLimit === 10000000) {
    //   return null;
    // }
    // const requestCount = 5000;

    let submissionClassName = '';
    let emailClassName = '';
    let smsClassName = '';    

    if (submissionCount/submissionLimit > .7) {
      submissionClassName += ' warning';
    }

    if (submissionCount/submissionLimit >= 1) {
      submissionClassName += ' exceeded';
    }

    if (emailCount/emailLimit > .7) {
      emailClassName += ' warning';
    }

    if (emailCount/emailLimit >= 1) {
      emailClassName += ' exceeded';
    }

    if (smsCount/smsLimit > .7) {
      smsClassName += ' warning';
    }

    if (smsCount/smsLimit >= 1) {
      smsClassName += ' exceeded';
    }

    let header = (<SectionHeader
      title=<span><i class="fas fa-calendar"></i>Monthly Limits</span>
      subtitle="See your limits for this month."
      tooltip="If you reach your submission limit, your surveys will stop showing on your website. If you reach an email limit you will no longer be able to send outbound emails. If you want more submissions or emails you can change your plan."
    />);

    const url = `/a/${encode(this.props.account._id)}/manage`;
    if (this.props.hideHeader) {
      header = null;
    }

    const tooltip = (<Tooltip>If you reach your submission limit, your surveys will stop showing on your website. If you want more submission per month you can <a style={{color: "#fff", textDecoration: 'underline' }} href={url}>change your plan</a>.</Tooltip>);

    const emailTooltip = (<Tooltip>If you reach your email limit, you will no longer be able to send emails via Zigpoll for the month. If you want to send more emails per month you can <a style={{color: "#fff", textDecoration: 'underline' }} href={url}>change your plan</a>.</Tooltip>);

    const smsTooltip = (<Tooltip>If you reach your SMS limit, you will no longer be able to send messages via Zigpoll for the month. If you want to send more SMS messages per month you can <a style={{color: "#fff", textDecoration: 'underline' }} href={url}>change your plan</a>.</Tooltip>);

    let submissionBar = (
      <div className={`${this.props.inline ? '' : 'card' } ${submissionClassName}`}>
        <div className="info">
          <label>Submissions{tooltip}</label>
          <p><span title={submissionCount || 0}>{ renderNumber(submissionCount) }</span> / <span title={submissionLimit || 0}>{ renderNumber(submissionLimit) }</span><span className="per-mo">this month</span><a className="change-account" href={url}>Change Plan</a></p>

          <div className="bar">
            <div className="bg" />
            <div className="fill" style={{ width: `${ (submissionCount / submissionLimit) * 100 }%`}}/>
          </div>
        </div>
      </div>
    );

    if (submissionLimit === 10000000) {
      // submissionBar = <div className={`${this.props.inline ? '' : 'card' } ${submissionClassName} big`}>
      //   <div className="info">
      //     <label>Submissions</label>
      //     <p><span title={submissionCount || 0}>{ renderNumber(submissionCount) }</span> submissions this month</p>
      //   </div>
      // </div>;

      submissionBar = <div className={`${this.props.inline ? '' : 'card' }`}>
        <div className="info">
          <label>Submissions{tooltip}</label>
          <p><span title={submissionCount || 0}>{ renderNumber(submissionCount) }</span> / <span title={submissionLimit || 0}><i class="fa-solid fa-infinity"></i></span> <span className="per-mo">this month</span><a className="change-account" href={url}>Change Plan</a></p>

          <div className="bar">
            <div className="bg" />
            <div className="fill" />
          </div>
        </div>
      </div>
    }

    let emailBar = (
      <div className={`${this.props.inline ? '' : 'card' } ${emailClassName}`}>
        <div className="info">
          <label>Outbound Emails{emailTooltip}</label>
          <p><span title={emailCount || 0}>{ renderNumber(emailCount) }</span> / <span title={emailLimit || 0}>{ renderNumber(emailLimit) } </span> <span className="per-mo">this month</span> <a className="change-account" href={url}>Change Plan</a></p>

          <div className="bar">
            <div className="bg" />
            <div className="fill" style={{ width: `${ (emailCount / emailLimit) * 100 }%`}}/>
          </div>
        </div>
      </div>
    );
    
    if ((this.props.account.plan.key === 'lite') && (this.props.account.shop === undefined) && (this.props.account.plan.allowEmailCampaigns === undefined)) {
      emailBar = null;
    }

    let smsBar = (
      <div className={`${this.props.inline ? '' : 'card' } ${smsClassName}`}>
        <div className="info">
          <label>Sms Messages{smsTooltip}</label>
          <p><span title={smsCount || 0}>{ renderNumber(smsCount) }</span> / <span title={smsLimit || 0}>{ renderNumber(smsLimit) }</span> <span className="per-mo">this month</span> <a className="change-account" href={url}>Change Plan</a></p>

          <div className="bar">
            <div className="bg" />
            <div className="fill" style={{ width: `${ (smsCount / smsLimit) * 100 }%`}}/>
          </div>
        </div>
      </div>
    );

    // if (this.props.account.shop === undefined) {
    //   smsBar = null;
    // }

    if (!submissionBar && !emailBar && !smsBar) {
      return null;
    }

    return (
    <div>
      <div className={`monthly-request-info ${this.props.inline ? 'card inline' : '' }`} style={this.props.style || {}}>
        { header }

        { submissionBar }

        { emailBar }

        { smsBar }
      </div>
      { this.props.inline && <div className="change-plan-banner">
        Want to increase your <a href="https://www.zigpoll.com/pricing" target="_blank">limits per month</a>? <a href={url} target="_blank">Change your plan</a> or <a onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          loadContactUsSurvey(this.props.user);
        }}>contact us</a>.
      </div> }
    </div>
    );
  }
}